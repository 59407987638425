/* YourComponent.css */

.container {
    padding: 20px 10px;
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; /* Align at the start (top) of the container */
  }
  
  .card {
    max-width: 400px;
    margin: 0 10px 20px;
    display: flex;
    flex-direction: column;
  }
  
  .card-content {
    flex: 1; /* Allow the content to take up remaining space */
  }
  
  @media (max-width: 599px) {
    .card-container {
      flex-direction: column;
      align-items: center;
    }
  
    .card {
      margin: 0 0 20px;
    }
  }
  